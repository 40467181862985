<template>
  <div
    class="csn-lsm"
    :class="{ 'csn-lsm-displayed': SHOWS_RESEND_CONFIRM_SIDE_MENU }"
  >
    <div class="csn-lsm-header">
      <button @click="handleArrowClick" class="csn-lsm-arrow-back">
        <SlideArrow />
      </button>
      <NavbarLogoButton @click.native="handleCloseClick" />
      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>
    <ResendConfirmationEmail />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  RESEND_CONFIRMATION_SIDE_MENU,
  Module,
  RouteName,
  TOGGLE_MAIN_MENU,
  SHOWS_RESEND_CONFIRM_SIDE_MENU,
  TOGGLE_RESEND_CONFIRM_SIDE_MENU,
} from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: RESEND_CONFIRMATION_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    ResendConfirmationEmail: () => import('@/modals/ResendConfirmationEmail'),
  },
  computed: {
    ...mapState(Module.MAIN, [SHOWS_RESEND_CONFIRM_SIDE_MENU]),
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MAIN_MENU,
      TOGGLE_RESEND_CONFIRM_SIDE_MENU,
    ]),
    async handleArrowClick() {
      await this.TOGGLE_RESEND_CONFIRM_SIDE_MENU(false)
      navigateTo({ name: RouteName.REGISTRATION })
    },
    async handleCloseClick() {
      await this.TOGGLE_RESEND_CONFIRM_SIDE_MENU(false)
      navigateTo({ name: RouteName.HOME })
    },
  },
}
</script>
